export var rid               = /^#(?:[\w-]|\\.|[^\x00-\xa0])*$/,
		   rclass            = /^\.(?:[\w-]|\\.|[^\x00-\xa0])*$/,
		   rhtml             = /<.+>/,
		   rtag              = /^\w+$/,
		   rsplitValues      = /\S+/g,
		   rcamelCase        = /-([a-z])/g,
		   rfragment         = /^\s*<(\w+)[^>]*>/,
		   rsingleTag        = /^<(\w+)\s*\/?>(?:<\/\1>)?$/,
		   rcssVariable      = /^--/,
		   //rcssProperty      = /^(.*?)(em|ex|%|px|cm|mm|in|pt|pc|ch|rem|vh|vw|vmin|vmax|deg)?$/,
		   rJSONString       = /^\s+|\s+$/,
		   reventsMouse      = /^(mouse|pointer|contextmenu|drag|drop|click|dblclick)/i,
		   //rqueryEncodeSpace = /%20/g,
		   //rqueryEncodeCRLF  = /\r?\n/g,
		   //rskippable        = /file|reset|submit|button|image/i,
		   rcheckable        = /radio|checkbox/i,
		   rHTMLCDATA        = /^\s*<!(?:\[CDATA\[|--)|(?:\]\]|--)>\s*$/g,
		   rscriptType       = /^$|^module$|\/(java|ecma)script/;
