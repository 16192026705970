export var evNamespace     = '___ce';
export var evNamespacesSep = '.';
export var evFocus         = {
	focus: 'focusin',
	blur: 'focusout'
};
export var evHover         = {
	mouseenter: 'mouseover',
	mouseleave: 'mouseout'
};
